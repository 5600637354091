export default [
	{
		rel: 'icon',
		type: 'image/png',
		sizes: '16x16',
		href: 'https://ochsnersport.scene7.com/asset/ochsnersport/int/w16/h16/favicon.png',
	},
	{
		rel: 'icon',
		type: 'image/png',
		sizes: '32x32',
		href: 'https://ochsnersport.scene7.com/asset/ochsnersport/int/w32/h32/favicon.png',
	},
	{
		rel: 'icon',
		type: 'image/png',
		sizes: '96x96',
		href: 'https://ochsnersport.scene7.com/asset/ochsnersport/int/w96/h96/favicon.png',
	},
	{
		rel: 'preload',
		href: '/fonts/lineto-alpha/lineto-alpha--700.woff2',
		as: 'font',
		crossorigin: 'anonymous',
	},
	{
		rel: 'preload',
		href: '/fonts/ochsnersport/ochsnersport-2021-11-16.woff2',
		as: 'font',
		crossorigin: 'anonymous',
	},
	{ rel: 'preload', href: '/fonts/roboto/roboto--400.woff2', as: 'font', crossorigin: 'anonymous' },
	{ rel: 'preload', href: '/fonts/roboto/roboto--700.woff2', as: 'font', crossorigin: 'anonymous' },

	{
		rel: 'preload',
		href: 'https://cdn.consentmanager.net/delivery/js/modules/display_custom.min.js',
		as: 'script',
	},
];
