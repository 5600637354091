import { Component, Vue } from 'vue-property-decorator';
import { addInnerHtml } from '@/node_modules/@osp/design-system/assets/js/utilities/addInnerHtml';
import { useRoutingStore } from '~/@api/store/routingApi';

@Component({})
export default class RichSnippet extends Vue {
	get richSnippet() {
		return useRoutingStore(this.$store).state.spaData.richSnippet;
	}

	public render(h) {
		return this.richSnippet
			? h('script', {
					attrs: { type: 'application/ld+json' },
					...addInnerHtml(this.richSnippet),
				})
			: null;
	}
}
